import styled from "styled-components";
import { useState } from "react";
import React from "react";
import { LuPhone, LuRedo, LuMail, LuAlertOctagon } from "react-icons/lu";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  BoxContent,
  LoadingIcon,
  Title,
  Button,
} from "../../components/StyledComponent";
const SupportPolicy = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  return (
    <div className="container">
      <Title>Privacy Policy</Title>
      <BoxContent>
        <LuAlertOctagon />
        <p>
          Lorem ipsum dolor sit, amet consectetur adipisicing elit. Alias
          aliquid eveniet quo eaque reprehenderit enim cupiditate ducimus neque
          asperiores earum tempore, necessitatibus animi placeat vero, dicta
          laboriosam dolor ab. Voluptate, aperiam! Sint, dolore officia. Quia
          iste consequuntur non ratione aliquid! Temporibus pariatur magni
          placeat, laudantium quos doloremque. Enim, voluptates maxime.
        </p>
      </BoxContent>
    </div>
  );
};

export default SupportPolicy;
