import styled from "styled-components";
import { useState } from "react";
import React from "react";
import { LuPhone, LuRedo, LuMail, LuAlertOctagon } from "react-icons/lu";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  BoxContent,
  LoadingIcon,
  Title,
  Button,
} from "../../components/StyledComponent";
const SupportTerms = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  return (
    <div className="container">
      <Title>Terms and Conditions</Title>
      <BoxContent>
        <LuAlertOctagon />
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Distinctio,
          consectetur voluptates quidem ut porro adipisci illum aspernatur
          magni, voluptatem quos aut perspiciatis autem, modi odio velit vitae
          alias sit ad. Ipsa omnis veritatis eligendi vitae quas. Accusamus,
          voluptatum sit nulla harum expedita, dolorum fuga aut tenetur,
          necessitatibus illo consequatur ipsam!
        </p>
      </BoxContent>
    </div>
  );
};

export default SupportTerms;
