import styled from "styled-components";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Title,
  LoadingIcon,
  Description,
  Input,
  Button,
  InputLabel,
  InputContainer,
  ErrorMessage,
  IconContainer,
  EyeIcon,
  ResendLink,
} from "../../components/StyledComponent";
import { useState } from "react";
import { LuRedoDot } from "react-icons/lu";
import { useNavigate } from "react-router-dom";

const changeBirthday = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  return (
    <div className="container">
      <Title>Update Date of Birth</Title>
      <InputContainer>
        <Input />
        <InputLabel>Date of Birth</InputLabel>
      </InputContainer>

      <Button>
        {/* {loading ? <LoadingIcon /> : "Save"} */}
        Save
      </Button>
    </div>
  );
};

export default changeBirthday;
