import { Title, SubTitle, SectionContainer, TextItem } from "./StyledComponent";
import LoginService from "../api/LoginService";
import { Button } from "./StyledComponent";
import React from "react";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
const Summary = () => {
  const navigate = useNavigate();
  const idToken = useSelector((state) => state.user.idToken);
  const location = useLocation();
  const plans = location.state?.plans || [];
  const [clientInfo, setClientInfo] = useState({
    firstName: "",
    lastName: "",
    email: "",
  });
  const { summaryDetails, planDetails } = useSelector(
    (state) => state.userInfo
  );
  // console.log("idToken being used:", idToken);
  // console.log("Request Headers:", {
  //   Authtoken: idToken,
  // });
  const formatCurrency = (amount) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
    }).format(amount);
  };

  const formatDate = (date) => {
    return new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
      // hour: "numeric",
      // minute: "numeric",
    }).format(new Date(date));
  };
  const getEndDate = () => {
    const today = new Date();
    const endDate = new Date(today.setDate(today.getDate() + 30));
    return endDate.toISOString().split("T")[0];
  };

  const handleNext = () => {
    navigate("/myplan");
  };
  //ANCHOR -  Fetch client info when the component mounts
  useEffect(() => {
    const fetchClientInfo = async () => {
      try {
        // console.log("Using idToken in fetchClientInfo:", idToken);
        const response = await LoginService.getBasicInfo(idToken);
        if (response) {
          setClientInfo({
            firstName: response.Firstname,
            lastName: response.LastName,
            email: response.Email,
          });
        }
      } catch (error) {
        console.error("Error fetching client info:", error);
      }
    };

    fetchClientInfo();
  }, []);

  return (
    <div className="container">
      <Title>Thank you!</Title>
      <SubTitle>Order Summary</SubTitle>
      <SectionContainer>
        <TextItem>
          <span>First Name: </span>
          {clientInfo.firstName}
        </TextItem>
        <TextItem>
          <span>Last Name: </span>
          {clientInfo.lastName}
        </TextItem>
        <TextItem>
          <span>Email: </span> {clientInfo.email}
        </TextItem>
        {/* <TextItem>
          Activation Date: {formatDate(summaryDetails.activationDate)}
        </TextItem> */}
      </SectionContainer>

      <SubTitle>Plan Details</SubTitle>
      <SectionContainer>
        <TextItem>
          <span>Plan Amount: </span>
          {formatCurrency(planDetails.plan_Amt)} {planDetails?.currency}
        </TextItem>
        <TextItem>
          <span>Duration: </span>
          {planDetails?.charge_Duration}
        </TextItem>
        {/* <TextItem>Subtotal: {formatCurrency(planDetails.subtotal)}</TextItem> */}
        <TextItem>
          <span>GST: </span> {formatCurrency(planDetails.gst_Amt)}
        </TextItem>
        <TextItem>
          <span>PST: </span> {formatCurrency(planDetails.pst_Amt)}
        </TextItem>
        <TextItem>
          <span>SIM card fee: </span> {formatCurrency(planDetails.simcard_amt)}
        </TextItem>
        <TextItem
          style={{ borderTop: "1px solid #BEBEBA", paddingTop: "10px" }}
        >
          <span style={{ fontSize: "20px", fontWeight: "bold" }}>Total: </span>
          <span style={{ fontSize: "20px", fontWeight: "bold" }}>
            {formatCurrency(planDetails.total)}
          </span>
        </TextItem>
      </SectionContainer>

      <Button onClick={handleNext}>My Plans</Button>
    </div>
  );
};

export default Summary;
