import styled from "styled-components";
import { useState } from "react";
import React from "react";
import { LuPhone, LuRedo, LuMail } from "react-icons/lu";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  BoxContent,
  LoadingIcon,
  Title,
  Button,
} from "../../components/StyledComponent";
const SupportCS = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  return (
    <div className="container">
      <Title>Customer Service</Title>
      <BoxContent>
        <LuPhone />{" "}
        <p>
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Repellendus
          qui, dolorum corporis id veniam minima nisi natus.
        </p>
        <LuMail />
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsum
          adipisci maxime laborum veniam accusamus
        </p>
      </BoxContent>
    </div>
  );
};

export default SupportCS;
