import styled from "styled-components";
import React from "react";
import { Button, Title } from "./StyledComponent";
import {
  Input,
  InputContainer,
  InputLabel,
  ErrorMessage,
  EyeIcon,
} from "./StyledComponent";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import SignUpService from "../api/SignUpService";
import { colors } from "./Constants";
import { RiEyeFill, RiEyeCloseLine } from "react-icons/ri";
const Register = () => {
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [validationErrors, setValidationErrors] = useState({
    email: "",
    firstName: "",
    lastName: "",
    password: "",
  });
  const [showPassword, setShowPassword] = useState(false);

  //SECTION - onBlur check for error
  const validateOnBlur = (fieldName) => {
    const error = { ...validationErrors };
    switch (fieldName) {
      case "firstName":
        error.firstName =
          firstName.trim() === "" ? "First Name is required" : "";
        break;
      case "lastName":
        error.lastName = lastName.trim() === "" ? "Last Name is required" : "";
        break;
      case "email":
        error.email = validateEmail(email)
          ? ""
          : "Please enter a valid email address.";
        break;
      case "password":
        error.password = validatePassword(password)
          ? ""
          : "Password must be at least 8 characters long and include at least one number, one uppercase letter, and one special character.";
        break;
      default:
        break;
    }

    setValidationErrors(error);
  };

  const validateOnPressCreateAccount = async () => {
    const _validationErrors = {
      firstName: firstName.trim() === "" ? "First Name is required" : "",
      lastName: lastName.trim() === "" ? "Last Name is required" : "",
      email: validateEmail(email) ? "" : "Please enter a valid email address.",
      password: validatePassword(password)
        ? ""
        : "Password must be at least 8 characters long and include at least one number, one uppercase letter, and one special character.",
    };
    setValidationErrors(_validationErrors);
    if (Object.values(validationErrors).some((error) => error)) {
      return;
    }

    await onPressCreateAnAccount();
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePassword = (password) => {
    return (
      password.length >= 8 &&
      /[A-Z]/.test(password) &&
      /\d/.test(password) &&
      /[\W_]/.test(password)
    );
  };
  //ANCHOR - deal with otp
  const onPressCreateAnAccount = async () => {
    try {
      const signUpResponse = await SignUpService.signUp({
        FirstName: firstName,
        LastName: lastName,
        Email: email,
        Password: password,
      });
      if (signUpResponse.Message === "User already exists") {
        alert("User already exists. Log in please!");
        navigate("/login");
        //FIXME - check
        console.log("Input Values:", {
          FirstName: firstName,
          LastName: lastName,
          Email: email,
          Password: password,
        });
      } else if (signUpResponse.IsSuccess) {
        alert("OTP send to your email!");
        navigate("/emailcode", {
          state: {
            firstName,
            lastName,
            email,
            password,
            userId: signUpResponse.UserId,
            from: "register",
          },
        });
      } else {
        console.error("Registration failed: ", signUpResponse.Message);
        alert(
          signUpResponse.Message || "Registration failed. Please try again."
        );
      }
    } catch (error) {
      if (error.response) {
        console.error("Error response data:", error.response.data);
        alert(error.response.data.Message || "Server error occurred.");
      } else {
        console.error("Unknown error during account creation: ", error);
        alert("An unexpected error occurred. Please try again.");
      }
    }
  };
  //SECTION - render
  return (
    <div className="container">
      <Title>Register</Title>
      {/* 1️⃣ name */}
      <InputContainer>
        <Input
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          onBlur={() => validateOnBlur("firstName")}
          error={validationErrors.firstName}
        />
        <InputLabel>First name</InputLabel>
        {validationErrors.firstName && (
          <ErrorMessage>{validationErrors.firstName}</ErrorMessage>
        )}
      </InputContainer>
      {/* 2️⃣ family name */}
      <InputContainer>
        <Input
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          onBlur={() => validateOnBlur("lastName")}
          error={validationErrors.lastName}
        />
        <InputLabel>Last name</InputLabel>
        {validationErrors.lastName && (
          <ErrorMessage>{validationErrors.lastName}</ErrorMessage>
        )}
      </InputContainer>
      {/* 3️⃣ email */}
      <InputContainer>
        <Input
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          onBlur={() => validateOnBlur("email")}
          error={validationErrors.email}
        />
        <InputLabel>Email</InputLabel>
        {validationErrors.email && (
          <ErrorMessage>{validationErrors.email}</ErrorMessage>
        )}
      </InputContainer>
      {/* 4️⃣ password */}
      <InputContainer>
        <Input
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          onBlur={() => validateOnBlur("password")}
          type={showPassword ? "text" : "password"}
          error={validationErrors.password}
        />
        <EyeIcon onClick={() => setShowPassword((prev) => !prev)}>
          {showPassword ? <RiEyeCloseLine /> : <RiEyeFill />}
        </EyeIcon>
        <InputLabel>Password</InputLabel>
      </InputContainer>
      {validationErrors.password && (
        <p style={{ color: colors.error, fontSize: "9px", fontWeight: "bold" }}>
          {validationErrors.password}
        </p>
      )}{" "}
      <Button onClick={validateOnPressCreateAccount}>Submit</Button>
    </div>
  );
};

export default Register;
