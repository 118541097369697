import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import React from "react";
import {
  Button,
  Title,
  Input,
  InputContainer,
  InputLabel,
  BoxContent,
} from "../../components/StyledComponent";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const PaymentHistory = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  return (
    <div className="container">
      <Title>Payment History</Title>

      <div>
        <p>
          1. Lorem ipsum dolor sit amet consectetur adipisicing elit. Nam
          tempora
        </p>
        <p>
          2. Lorem ipsum dolor sit amet consectetur adipisicing elit. Nam
          tempora
        </p>
        <p>
          3. Lorem ipsum dolor sit amet consectetur adipisicing elit. Nam
          tempora
        </p>
        <p>
          4. Lorem ipsum dolor sit amet consectetur adipisicing elit. Nam
          tempora
        </p>
      </div>
    </div>
  );
};

export default PaymentHistory;
