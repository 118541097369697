import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { Title, Button } from "../../components/StyledComponent";
import {
  BsGlobe2,
  BsPhone,
  BsFillInfoCircleFill,
  BsReceipt,
} from "react-icons/bs";
import { colors } from "../../components/Constants";

const SimCard = () => {
  const navigate = useNavigate();

  return (
    <div className="container">
      <Header>
        <Title>SIM Card</Title>
        <ReplaceButton>Replace your SIM</ReplaceButton>
      </Header>

      <Section>
        <DetailItem>
          <DetailIcon>
            <BsGlobe2 />
          </DetailIcon>
          <DetailText>
            <span>Service Provider</span>
            <span>AT&T</span>
          </DetailText>
        </DetailItem>

        <DetailItem>
          <DetailIcon>
            <BsPhone />
          </DetailIcon>
          <DetailText>
            <span>SIM ICCID #</span>
            <span>89014104274143188192</span>
          </DetailText>
        </DetailItem>

        <DetailItem>
          <DetailIcon>
            <BsReceipt />
          </DetailIcon>
          <DetailText>
            <span>SIM Type</span>
            <span>SIM</span>
          </DetailText>
        </DetailItem>
      </Section>
    </div>
  );
};

// Styled Components
const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const Section = styled.div`
  margin-top: 20px;
  border-top: 1px solid ${colors.division};
  padding-top: 15px;
`;

const DetailItem = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid ${colors.division};
`;

const DetailIcon = styled.div`
  font-size: 18px;
  margin-right: 15px;
  color: ${colors.primary};
`;

const DetailText = styled.div`
  display: flex;
  flex-direction: column;
`;

const ReplaceButton = styled.button`
  font-size: 7px;
  font-weight: bold;
  padding: 7px 10px;
  margin-bottom: 12px;
  background-color: ${colors.division};
  border-radius: 20px;
  border: none;
  cursor: pointer;
`;

export default SimCard;
