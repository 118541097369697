import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import {
  Title,
  Box,
  Button,
  BoxContent,
} from "../../components/StyledComponent";
import { LuCalendar, LuSmartphone } from "react-icons/lu";
import { IoIosArrowForward } from "react-icons/io";
import { colors } from "../../components/Constants";
const MyPlan = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/plans");
  };

  return (
    <div className="container">
      <Header>
        <Title>My Plans</Title>
        <SettingsButton onClick={() => navigate("/setting")}>
          Account setting
        </SettingsButton>
      </Header>

      <Box>
        <span style={{ fontSize: "24px", fontWeight: "bold" }}>Light Plan</span>
        <span style={{ fontSize: "24px" }}>$30/month</span>
        <span style={{ fontSize: "14px", color: colors.description }}>
          Unlimited Calls & Messages with 1GB of Data
        </span>
      </Box>

      <BoxContent>
        <UsageInfo>
          <UsageUsed>
            <span style={{ fontSize: "20px" }}>346 MB</span>
            <span>Used</span>
          </UsageUsed>
          <UsageTotal>1 GB total</UsageTotal>
        </UsageInfo>

        <UsageBar>
          <UsageProgress style={{ width: "40%" }} />
        </UsageBar>

        <span
          style={{
            fontSize: "9px",
            color: colors.description,
            margin: "5px",
          }}
        >
          Data usage data can be delayed by up to 48 hours
        </span>
        <DeviceInfo>
          <DeviceRow>
            <DeviceIcon>
              <LuSmartphone />
            </DeviceIcon>
            <DeviceText>
              <span style={{ color: colors.description }}>
                Light Phone Light Phone II
              </span>
              <span>+1 (917) 825-3988</span>
            </DeviceText>
          </DeviceRow>
          <DeviceRow>
            <DeviceIcon>
              <LuCalendar />
            </DeviceIcon>
            <DeviceText>
              <span style={{ color: colors.description }}>Next Renewal</span>
              <span>Thursday, Apr 25, 2024, 9:41 PM</span>
            </DeviceText>
          </DeviceRow>
          <SubscriptionDetails onClick={() => navigate("/subscription")}>
            <span>Subscription Details</span>
            <IoIosArrowForward />
          </SubscriptionDetails>
        </DeviceInfo>
      </BoxContent>

      <Button onClick={handleClick}>Add a Plan</Button>
    </div>
  );
};

// Styled Components
const UsageInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
  gap: 8px;
`;

const UsageUsed = styled.div`
  display: flex;
  align-items: baseline;
  gap: 4px;
  font-size: 12px;
  font-weight: bold;
`;

const UsageTotal = styled.div`
  font-size: 12px;
  color: ${colors.primary};
  font-weight: bold;
`;

const UsageBar = styled.div`
  width: 100%;
  height: 8px;
  background-color: ${colors.littleBackground};
  border-radius: 4px;
  overflow: hidden;
  margin-top: 4px;
`;

const UsageProgress = styled.div`
  height: 100%;
  background-color: ${colors.primary};
`;

const DeviceInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
`;

const DeviceRow = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding-top: 10px;
  border-top: 1px solid ${colors.division};
`;

const DeviceIcon = styled.span`
  font-size: 16px;
  color: ${colors.primary};
`;

const DeviceText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 12px;
`;

const SubscriptionDetails = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 14px;
  cursor: pointer;
  text-align: left;
  padding-top: 20px;
  font-weight: bold;
  border-top: 1px solid ${colors.division};
`;
const SettingsButton = styled.button`
  font-size: 11px;
  font-weight: bold;
  padding: 7px 10px;
  margin-bottom: 12px;
  background-color: ${colors.division};
  border-radius: 20px;
  border: none;
  cursor: pointer;
`;
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export default MyPlan;
