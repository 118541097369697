import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import React from "react";
import {
  Title,
  LoadingIcon,
  Description,
  Input,
  Button,
  InputLabel,
  InputContainer,
  ErrorMessage,
  IconContainer,
  EyeIcon,
  ResendLink,
} from "../../components/StyledComponent";
import { useState } from "react";
import { LuRedoDot } from "react-icons/lu";
import { useNavigate } from "react-router-dom";

const ChangeCreditCard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  return (
    <div className="container">
      <Title>Update Credit Card </Title>

      {/* Credit Card Number */}
      <InputContainer>
        <Input />
        <InputLabel>Credit Card Number</InputLabel>
      </InputContainer>

      {/* Cardholder Name */}
      <InputContainer>
        <Input />
        <InputLabel>Cardholder Name</InputLabel>
      </InputContainer>

      {/* Expiration Date */}
      <InputContainer>
        <Input />
        <InputLabel>Expiration Date (MM/YY)</InputLabel>
      </InputContainer>

      {/* CVV */}
      <InputContainer>
        <Input />
        <InputLabel>CVV</InputLabel>
      </InputContainer>

      <Button>Save and Continue</Button>
    </div>
  );
};

export default ChangeCreditCard;
