import styled from "styled-components";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import LoginService from "../api/LoginService";
import {
  Title,
  LoadingIcon,
  Description,
  Input,
  Button,
  InputLabel,
  InputContainer,
  ErrorMessage,
  IconContainer,
} from "./StyledComponent";
import { useState } from "react";
import { LuRedoDot } from "react-icons/lu";
import { useNavigate } from "react-router-dom";

const ForgetPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const handleInputChange = (e) => {
    setEmail(e.target.value);
    setErrorMessage("");
  };
  const handleBlur = () => {
    if (!validateEmail(email)) {
      setErrorMessage("Please enter a valid email address.");
    } else {
      setErrorMessage("");
    }
  };

  const handSubmit = async () => {
    if (!validateEmail(email)) {
      setErrorMessage("Please enter a valid email address.");
      return;
    }
    setLoading(true);
    try {
      const signUpResponse = await LoginService.forgetPassword(email);
      setLoading(false);
      if (signUpResponse.IsSuccess) {
        alert("OTP send to your email!");
        navigate("/emailcode", {
          state: {
            email,
            userId: signUpResponse.UserId,
            from: "forgetPassword",
          },
        });
      } else {
        console.error("Error during OTP verification:", signUpResponse.Message);
        alert(
          signUpResponse.Message || "OTP verification failed. Please try again."
        );
      }
    } catch (error) {
      setLoading(false);
      console.error("Error during OTP verification:", error);
      alert("An error occurred. Please try again.");
    }
  };
  return (
    <div className="container">
      <Title>Your email</Title>
      <InputContainer>
        <Input
          value={email}
          onChange={handleInputChange}
          onBlur={handleBlur}
          error={!!errorMessage}
        />
        <InputLabel>email</InputLabel>
        {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      </InputContainer>

      <Button onClick={handSubmit} disabled={loading}>
        {loading ? <LoadingIcon /> : "Send"}
      </Button>
    </div>
  );
};

export default ForgetPassword;
