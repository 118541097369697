import styled from "styled-components";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLoggedIn, setUserDetails } from "../api/UserSlice";
import { colors } from "./Constants";
import {
  Title,
  LoadingIcon,
  Description,
  Input,
  Button,
  InputLabel,
  InputContainer,
  ErrorMessage,
  IconContainer,
  EyeIcon,
  ResendLink,
} from "./StyledComponent";
import { useState } from "react";
import { LuRedoDot } from "react-icons/lu";
import LoginService from "../api/LoginService";
import { useNavigate } from "react-router-dom";
import { RiEyeFill, RiEyeCloseLine } from "react-icons/ri";
const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const loggedIn = useSelector((state) => state.user.loggedIn);

  const handleLogin = async () => {
    if (!email || !password) {
      setErrorMessage("Please enter both email and password.");
      return;
    }

    setLoading(true);
    try {
      const response = await LoginService.signIn({
        Email: email,
        Password: password,
      });

      if (response.IsSuccess) {
        alert("Login successful!");
        if (response.ClientRef === "") {
          navigate("/activation");
        } else {
          navigate("/myplan");
        }

        setTimeout(() => {
          dispatch(setLoggedIn(true));
          dispatch(
            setUserDetails({
              email,
              firstName: response.FirstName || "",
              lastName: response.LastName || "",
              userId: response.UserId,
              idToken: response.IdToken,
            })
          );

          localStorage.setItem("loggedIn", true);
          localStorage.setItem("email", email);
          localStorage.setItem("userId", response.UserId);
          localStorage.setItem("idToken", response.IdToken);

          //FIXME - console.log
          console.log("LocalStorage Values:", {
            loggedIn: localStorage.getItem("loggedIn"),
            email: localStorage.getItem("email"),
            userId: localStorage.getItem("userId"),
            idToken: localStorage.getItem("idToken"),
          });

          console.log("Dispatched Redux State:", {
            loggedIn: true,
            email: email,
            firstName: response.FirstName || "",
            lastName: response.LastName || "",
            userId: response.UserId,
            idToken: response.IdToken,
          });
        }, 0);
      } else {
        setErrorMessage("Invalid email or password. Please try again.");
      }
    } catch (error) {
      setErrorMessage("An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container">
      <Title>Login</Title>
      <InputContainer>
        <Input value={email} onChange={(e) => setEmail(e.target.value)} />
        <InputLabel>Email</InputLabel>
      </InputContainer>
      <InputContainer>
        <Input
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          type={showPassword ? "text" : "password"}
        />
        <EyeIcon onClick={() => setShowPassword((prev) => !prev)}>
          {showPassword ? <RiEyeCloseLine /> : <RiEyeFill />}
        </EyeIcon>
        <InputLabel>Password</InputLabel>
      </InputContainer>
      {errorMessage && (
        <p style={{ color: "red", fontSize: "10px", fontWeight: "bold" }}>
          {errorMessage}
        </p>
      )}
      <Description>
        <ResendLink onClick={() => navigate("/forgetPassword")}>
          Forget your password?
        </ResendLink>
      </Description>
      <Button onClick={handleLogin} disabled={loading}>
        {loading ? <LoadingIcon /> : "Continue"}
      </Button>
    </div>
  );
};

export default Login;
