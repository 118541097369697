import React from "react";
import styled from "styled-components";
import { IoIosArrowBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { IoHomeOutline } from "react-icons/io5";
const Header = () => {
  const navigate = useNavigate();
  const handleBackClick = () => {
    navigate(-1);
  };
  const handleBackHome = () => {
    navigate("/");
  };
  const location = useLocation();

  if (location.pathname === "/") {
    return null;
  }

  return (
    <Wrapper>
      <BackButton className="back-button" onClick={handleBackClick}>
        <IoIosArrowBack size={24} />
      </BackButton>
      <HomeButton onClick={handleBackHome}>
        <IoHomeOutline />
      </HomeButton>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: 10%;
  width: 100%;
  /* display: flex;
  justify-content: center;
  align-items: center; */
  background-color: white;
  /* border: 2px solid blue; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
const HomeButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: black;
  font-size: 1.5rem;
  margin: 5px 10px 0 0;
`;
const BackButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: black;
  font-size: 1.5rem;
  &:hover {
    color: #0056b3;
  }
`;

export default Header;
