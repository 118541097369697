import styled from "styled-components";
import { useState } from "react";
import React from "react";
import {
  LuPhone,
  LuRedo,
  LuMail,
  LuShieldQuestion,
  LuMessageCircle,
} from "react-icons/lu";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  BoxContent,
  LoadingIcon,
  Title,
  Button,
} from "../../components/StyledComponent";
const SupportFaq = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  return (
    <div className="container">
      <Title>FAQ</Title>
      <div>
        <LuShieldQuestion />
        <div>
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Repellendus
          qui, dolorum corporis id veniam minima nisi natus.
        </div>
        <LuMessageCircle />
        <div>
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Repellendus
          qui, dolorum corporis id veniam minima nisi natus.
        </div>
      </div>
    </div>
  );
};

export default SupportFaq;
