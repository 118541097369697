import { sharedInstance } from "./axios";
const LoginService = {
  //SECTION - login user
  signIn: async (data) => {
    try {
      const response = await sharedInstance.post(
        "/api/LoginRegistration/LightPhoneLogin",
        data
      );
      return response.data;
    } catch (error) {
      console.error(
        "Error during login:",
        error.response?.data || error.message
      );
      throw error;
    }
  },

  //SECTION - forget password
  forgetPassword: async (data) => {
    try {
      const response = await sharedInstance.post(
        "/api/LoginRegistration/LightPhoneForgotPassword",
        data
      );
      return response.data;
    } catch (error) {
      console.error(
        "Error during forget password request:",
        error.response?.data || error.message
      );
      throw error;
    }
  },
  //SECTION - reset password
  resetPassword: async (data) => {
    try {
      const response = await sharedInstance.post(
        "/api/LoginRegistration/LightPhoneResetPassword",
        data
      );
      return response.data;
    } catch (error) {
      console.error(
        "Error during reset password request:",
        error.response?.data || error.message
      );
      throw error;
    }
  },
  //SECTION - SIM card activation
  validateSimCard: async (iccid) => {
    try {
      const response = await sharedInstance.get(
        "/api/Activation/ValidateSimCard",
        {
          params: { iccid },
        }
      );
      return response.data;
    } catch (error) {
      console.error(
        "Error during SIM card validation:",
        error.response?.data || error.message
      );
      throw error;
    }
  },
  //SECTION - get client info
  getBasicInfo: async (idToken) => {
    try {
      const response = await sharedInstance.get("/api/Account/GetBasicInfo", {
        headers: {
          Authtoken: idToken,
        },
      });
      return response.data;
    } catch (error) {
      console.error(
        "Error during get user info:",
        error.response?.data || error.message
      );
      throw error;
    }
  },
  //SECTION - get post activation info
  postActivationInfoPrepaid: async ({ idToken, ...requestData }) => {
    try {
      const response = await sharedInstance.post(
        "/api/Activation/PostActivationInfoPrepaid",
        requestData,
        {
          headers: {
            Authtoken: idToken,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error(
        "Error during get post-activation info:",
        error.response?.data || error.message
      );
      throw error;
    }
  },
};

export default LoginService;
