import styled from "styled-components";
import React from "react";
import { Button, Title, Description } from "./StyledComponent";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { resetUser } from "../api/UserSlice";
import { useEffect } from "react";
import { resetUserInfo } from "../api/UserInfoSlice";
const HomePage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const loggedIn = useSelector((state) => state.user.loggedIn);
  // const firstName = useSelector((state) => state.user.firstName);
  useEffect(() => {
    if (!loggedIn) {
      navigate("/");
    }
  }, [loggedIn]);
  console.log("Logged In:", loggedIn);
  // console.log("First Name:", firstName);

  const handleRegister = () => {
    navigate("/register");
  };

  const handleLogin = () => {
    navigate("/login");
  };

  const handleLogout = () => {
    dispatch(resetUser());
    dispatch(resetUserInfo());
    localStorage.clear();
  };

  return (
    <div className="container" style={{ marginTop: "20px" }}>
      <Title style={{ marginLeft: "80px" }}>Welcome!</Title>
      <Wrapper>
        <LogoWrapper>
          <img src="/images/logo.png" alt="Logo" />
        </LogoWrapper>

        <ButtonWrapper>
          {!loggedIn && (
            <>
              <Button
                style={{
                  backgroundColor: "white",
                  color: "black",
                  marginBottom: "10px",
                }}
                onClick={handleLogin}
              >
                Log in
              </Button>
              <Button onClick={handleRegister}>Register</Button>
            </>
          )}
          {loggedIn && <Button onClick={handleLogout}>Logout</Button>}
        </ButtonWrapper>
      </Wrapper>
    </div>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  padding: 20px;
  box-sizing: border-box;
`;

const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0;
  margin-bottom: 100px;
  img {
    max-width: 80%;
    height: auto;
    object-fit: contain;
  }
`;

const ButtonWrapper = styled.div`
  width: 100%;
  max-width: 300px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
`;

export default HomePage;
