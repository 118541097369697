import axios from "axios";

const SHARED_API_URL = "https://lightphonemyaccount.beonesys.com";

export const sharedInstance = axios.create({
  baseURL: SHARED_API_URL,
  timeout: 60000,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    apitoken: "75e142e9fd9decba5da64d86874ce5b6",
    client: "alpha",
  },
});
