import React from "react";
import styled from "styled-components";
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import SignUpService from "../../api/SignUpService";
import LoginService from "../../api/LoginService";
import { Title } from "../../components/StyledComponent";
import { provinces } from "../../components/Constants";
import {
  LuUser,
  LuMail,
  LuHome,
  LuCalendar,
  LuCreditCard,
  LuClipboardList,
  LuPhone,
  LuShieldQuestion,
  LuFileSignature,
  LuLock,
} from "react-icons/lu";
import { IoIosArrowForward } from "react-icons/io";
import { colors } from "../../components/Constants";
const Setting = () => {
  const navigate = useNavigate();
  const idToken = useSelector((state) => state.user.idToken);
  // const location = useLocation();
  // const plans = location.state?.plans || [];
  const [clientInfo, setClientInfo] = useState({
    firstName: "",
    lastName: "",
    email: "",
  });
  const {
    firstName,
    lastName,
    email,
    confirmEmail,
    province,
    city,
    address,
    postalCode,
    phoneNumber,
    carrier,
    accountNumber,
    imeiNumber,
    portIn,
    simCardNumber,
    planDetails,
    summaryDetails,
  } = useSelector((state) => state.userInfo);
  // console.log("idToken being used:", idToken);

  //ANCHOR - Province code => Province name
  const getProvinceName = (code) => {
    const provinceObj = provinces.find((p) => p.code === code);
    return provinceObj ? provinceObj.name : code;
  };
  //ANCHOR - get client info:
  useEffect(() => {
    const fetchClientInfo = async () => {
      try {
        // console.log("Using idToken in fetchClientInfo:", idToken);
        const response = await LoginService.getBasicInfo(idToken);
        if (response) {
          setClientInfo({
            firstName: response.Firstname,
            lastName: response.LastName,
            email: response.Email,
          });
        }
      } catch (error) {
        console.error("Error fetching client info:", error);
      }
    };

    fetchClientInfo();
  }, []);
  //SECTION - render ↓
  return (
    <Container>
      <Title>Settings</Title>
      {/* Account Details Section */}
      <Section>
        <SectionTitle>Account Details</SectionTitle>
        <DetailsList>
          <DetailItem onClick={() => navigate("/setting/changeName")}>
            <DetailIcon>
              <LuUser />
            </DetailIcon>
            <DetailText>
              <span>Full Name</span>
              <span>
                {clientInfo.firstName} {clientInfo.lastName}
              </span>
            </DetailText>
            <Arrow>
              <IoIosArrowForward />
            </Arrow>
          </DetailItem>
          {/* email */}
          <DetailItem style={{ pointerEvents: "none" }}>
            <DetailIcon>
              <LuMail />
            </DetailIcon>
            <DetailText>
              <span>Email Address</span>
              <span>{clientInfo.email}</span>
            </DetailText>
          </DetailItem>
          {/* home address */}
          <DetailItem onClick={() => navigate("/setting/changeAddress")}>
            <DetailIcon>
              <LuHome />
            </DetailIcon>
            <DetailText>
              <span>Home Address</span>
              <span>
                {address}
                <br />
                {getProvinceName(province)}
                <br />
                Canada
                <br />
                {postalCode}
              </span>
            </DetailText>
            <Arrow>
              <IoIosArrowForward />
            </Arrow>
          </DetailItem>

          {/* date of birth */}
          {/* <DetailItem onClick={() => navigate("/setting/changeBirthday")}>
            <DetailIcon>
              <LuCalendar />
            </DetailIcon>
            <DetailText>
              <span>Date of Birth</span>
              <span>Jan 1, 2000</span>
            </DetailText>
            <Arrow>
              <IoIosArrowForward />
            </Arrow>
          </DetailItem> */}
        </DetailsList>
      </Section>

      {/* Billing Information Section */}
      <Section>
        <SectionTitle>Billing Information</SectionTitle>
        <DetailsList>
          <DetailItem onClick={() => navigate("/setting/changeCreditCard")}>
            <DetailIcon>
              <LuCreditCard />
            </DetailIcon>
            <DetailText>
              <span>Payment Method</span>
              <span>Amex •••• 1001</span>
            </DetailText>
            <Arrow>
              <IoIosArrowForward />
            </Arrow>
          </DetailItem>

          <DetailItem onClick={() => navigate("/setting/payment-history")}>
            <DetailIcon>
              <LuClipboardList />
            </DetailIcon>
            <DetailText>
              <span>Payment History</span>
            </DetailText>
            <Arrow>
              <IoIosArrowForward />
            </Arrow>
          </DetailItem>
        </DetailsList>
      </Section>

      {/* Support & Legal Section */}
      <Section>
        <SectionTitle>Support & Legal</SectionTitle>
        <DetailsList>
          <DetailItem onClick={() => navigate("/support/customer-service")}>
            <DetailIcon>
              <LuPhone />
            </DetailIcon>
            <DetailText>
              <span>Customer Service</span>
            </DetailText>
            <Arrow>
              <IoIosArrowForward />
            </Arrow>
          </DetailItem>

          <DetailItem onClick={() => navigate("/support/faq")}>
            <DetailIcon>
              <LuShieldQuestion />
            </DetailIcon>
            <DetailText>
              <span>FAQ</span>
            </DetailText>
            <Arrow>
              <IoIosArrowForward />
            </Arrow>
          </DetailItem>

          <DetailItem onClick={() => navigate("/support/terms")}>
            <DetailIcon>
              <LuFileSignature />
            </DetailIcon>
            <DetailText>
              <span>Terms of Service</span>
            </DetailText>
            <Arrow>
              <IoIosArrowForward />
            </Arrow>
          </DetailItem>

          <DetailItem onClick={() => navigate("/support/privacy-policy")}>
            <DetailIcon>
              <LuLock />
            </DetailIcon>
            <DetailText>
              <span>Privacy Policy</span>
            </DetailText>
            <Arrow>
              <IoIosArrowForward />
            </Arrow>
          </DetailItem>
        </DetailsList>
      </Section>
    </Container>
  );
};
const Container = styled.div`
  padding: 20px;
`;

const Section = styled.div`
  margin-bottom: 30px;
`;

const SectionTitle = styled.h2`
  font-size: 18px;
  font-weight: bold;
  ${colors.primary};
  margin-bottom: 10px;
`;

const DetailsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const DetailItem = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid ${colors.division};
  cursor: pointer;
`;

const DetailIcon = styled.span`
  font-size: 18px;
  margin-right: 10px;
`;

const DetailText = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  color: ${colors.primary};

  & span:first-child {
    font-size: 12px;
    color: ${colors.description};
  }

  & span:last-child {
    font-size: 14px;
    color: ${colors.primary};
  }
`;

const Arrow = styled.span`
  font-size: 18px;
  color: ${colors.primary};
`;
export default Setting;
